class fileModel {
    file: File;
    fileid : string;

    constructor(file: File, fileid: string) {
        this.file = file;
        this.fileid = fileid;
    }
}

let files: fileModel[] = [];

export const getFiles = () => files;
export const resetFiles = () => files = [];

export const initFilePicker = () => {
    $("#fileInput").off().on("change", function(e) {
        const fileInput = this as HTMLInputElement;

        // Check if files were selected
        if (fileInput.files && fileInput.files.length > 0) {
            // Get the first selected file
            const selectedFile = fileInput.files[0];
            const uniqueId = generateUniqueId();
            
            files.push(new fileModel(selectedFile, uniqueId));

            // Create a new file input element dynamically
            const newFileInput = $("<input type=\"file\" style=\"display: none;\" data-fileid=\"" + uniqueId + "\" />");

            // Set the value of the new file input to the selected file
            newFileInput.prop('files', fileInput.files);

            // Append the new file input to a form or another container in your HTML
            $('#formContainer').append(newFileInput);

            $(this).data("fileid", uniqueId)
            $("#file-container").append("" +
                `<div class='chosenfile' data-fileid='${uniqueId}'>` +
                    "<svg aria-hidden=\"true\" class=\"embla-icon\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\"><use xlink:href=\"/wwwroot/dist/icons/sprite.symbol.svg#document\"></use></svg>" +
                    "<div style='overflow-x: hidden'>" + selectedFile.name + "</div>" +
                    "<svg aria-hidden=\"true\" class=\"embla-icon close-button\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\"><use xlink:href=\"/wwwroot/dist/icons/sprite.symbol.svg#close\"></use></svg>" +
                "</div>")

            updateAttachedFileCounter();
            initCloseButtons();
        }
    })
}

const updateAttachedFileCounter = () => {
    if (files.length > 0){
        $("#attachedfilescounter").show();
        $("#attachedfilescounter").text(files.length);
    }
    else {
        $("#attachedfilescounter").hide();
    }
}

const initCloseButtons = () => {
    $(".close-button").off().on("click", function() {
        const fileid = $(this).parent().data("fileid");
        $("input[data-fileid=" + fileid + "]").remove();

        const indexToRemove = files.findIndex(file => file.fileid === fileid);
        if (indexToRemove !== -1) {
            files.splice(indexToRemove, 1);
        }

        $(this).parent().remove();
        updateAttachedFileCounter();
    })
}

const generateUniqueId = () => {
    const timestamp = new Date().getTime();
    const randomPart = Math.random().toString(36).substr(2, 9);

    return `${timestamp}${randomPart}`;
}