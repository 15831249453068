import "ditmer-embla";
import "datatables.net-bs";
import Sortable from "sortablejs";

export const SetDataTable = (tableSelector, aoColumnsSortOption, columnDefs, pageLength) => {

    const table = $(`${tableSelector}`);

    table.dataTable({
        paging: false,
        "order": [],
        "bStateSave": true,
        "bRetrieve": true,
        "oLanguage": {
            "oPaginate": {
                "sFirst": " << ",
                "sPrevious": " < ",
                "sLast": " >> ",
                "sNext": " > "
            },
            "sLengthMenu": "Vis _MENU_ rækker per side",
            "sSearch": "Søg",
            "sZeroRecords": "Intet fundet - desværre",
            "sInfo": "Viser _TOTAL_ resultater",
            "sInfoEmpty": "Viser 0 resultater",
            "sInfoFiltered": "(filtreret fra _MAX_ rækker)"
        },
        "aoColumns": aoColumnsSortOption,
        "columnDefs": columnDefs,
        "dom": "<'row data-header'<'col-sm-6'i><'col-sm-6'f>><'row data-container'<'col-sm-12'tr>><'row data-footer'>"
    });
    
    return table;
};

export const SetDataTableWithChildRow = (tableSelector, columns, childInfo) => {
    const table = $(`${tableSelector}`);

    table.dataTable({
        paging: false,
        "bStateSave": true,
        "oLanguage": {
            "oPaginate": {
                "sFirst": " << ",
                "sPrevious": " < ",
                "sLast": " >> ",
                "sNext": " > "
            },
            "sLengthMenu": "Vis _MENU_ rækker per side",
            "sSearch": "Søg",
            "sZeroRecords": "Intet fundet - desværre",
            "sInfo": "Viser _TOTAL_ resultater",
            "sInfoEmpty": "Viser 0 resultater",
            "sInfoFiltered": "(filtreret fra _MAX_ rækker)"
        },
        "order": [[1, 'asc']],
        "columns": columns,
        "dom": "<'row data-header'<'col-sm-6'i><'col-sm-6'f>><'row data-container'<'col-sm-12'tr>><'row data-footer'>"
    });

    childInfo(tableSelector, table);

    table.on("draw.dt", function () {
        childInfo(tableSelector, table);
    });
    
    return table;
}

$.fn.dataTable.ext.order["dom-checkbox"] = (settings, col) => {
    return this.api().column(col, { order: "index" }).nodes().map((td) => {
        return $("input", td).prop("checked") ? "1" : "0";
    });
};

export const setAlleCheckboxes = (type) => {
    const alleValgt = erAlleRettighederValgt(type);
    $(`.${type}`).each(function () {
        if (alleValgt) {
            $(this).prop("checked", false);
        } else {
            $(this).prop("checked", true);
        }
        $(this).trigger("change");
    });
};

export const erAlleRettighederValgt = (type) => {
    let alleValgt = true;
    $(`.${type}`).each(function () {
        if ($(this).prop("checked") === false) {
            alleValgt = false;
        }
    });

    return alleValgt;
};

export const bekraeftAfsendelse = (besked, antalUddannelsesforloeb) => {
    if (antalUddannelsesforloeb > 1) {
        return confirm(besked);
    }
    return true;
};

export const initSortable = (containerId, dragelementclass, callbackOnDragstopped) => {
    let sortableContainer = document.getElementById(containerId);
    let sortable = new Sortable(sortableContainer, {
        handle: "." + dragelementclass, // handle's class
        animation: 250,
        onEnd: () => {callbackOnDragstopped()}
    });
    
    return sortable;
};

export const setupCkEditor = (targetElement, editor) => {
    if (editor === null || editor === undefined) {
        CKEDITOR.config.removePlugins = "pastefromword,uploadimage,filebrowser,maximize,pastetext,image,specialchar,table,tabletools,tableselection,undo,blockquote,about,horizontalrule,,magicline,removeformat,stylescombo";
        CKEDITOR.on("instanceReady", function() {
            $(".cke_toolbar_break").hide();
        });
        editor = CKEDITOR.replace(targetElement, {
            language: 'da',
            entities: false
        });
    }

    return editor;
};

export const enableCharCountForEditor = (editor, maxBesvarelseLength) => {
    createElementsForCharCount(editor);
    handleEditorEvents(editor,maxBesvarelseLength);
};

const createElementsForCharCount = (editor) => {
    let editorElement = editor.element;
    let parentElement = editorElement.$.parentElement;
    let parentElementJquery = $(parentElement);
    let editorWidth = parentElementJquery.width();

    let charCountDiv = $("<div></div>")
        .attr("id", "char-count")
        .css("width", editorWidth);
    
    let errorDiv = $("<div>Du har nået grænsen for antal tegn</div>")
        .addClass("alert alert-danger")
        .attr("id", "error-div")
        .css("width", editorWidth)
        .css("display", "none");

    parentElementJquery.append(charCountDiv);
    parentElementJquery.append(errorDiv);
}

const handleEditorEvents = (editor, maxBesvarelseLength) => {
    // Handle existing data on load
    editor.on('instanceReady', function() {
        handleDataUpdate(editor, maxBesvarelseLength);
    });

    // Handle paste events
    editor.on('paste', function() {
        handlePasteUpdate(editor, maxBesvarelseLength);
    });

    // Handle key events
    editor.on('key', function() {
        handleDataUpdate(editor, maxBesvarelseLength);
    });
}

const handleDataUpdate = (editor, maxBesvarelseLength)=> {
    let textBeforeInput = editor.getData();
    
    setTimeout(function() {
        const errorDiv = $('#error-div');
        let charCountAfterInputWithoutHtml =  updateCharCount(editor);
        
        if (charCountAfterInputWithoutHtml >= maxBesvarelseLength) {
            errorDiv.show();
            errorDiv.text('Du har nået grænsen for antal tilladte tegn.');
            editor.setData(textBeforeInput);
            updateCharCount(editor);
        } else
            errorDiv.hide();
    }, 0);
}

const handlePasteUpdate = (editor, maxBesvarelseLength)=> {
    let textBeforeInput = editor.getData();
    
    setTimeout(function() {
        const errorDiv = $('#error-div');
        const textAfterInput = editor.getData();
        updateCharCount(editor);
        
        if (textAfterInput.length >= maxBesvarelseLength) {
            errorDiv.show();
            errorDiv.text('Du prøver at indsætte tekst der overstiger grænsen for antal tilladte tegn. Problemet kan skyldes, at du prøver at indsætte et billede');
            editor.setData(textBeforeInput);
            updateCharCount(editor);
        } else
            errorDiv.hide();
    }, 0);
}

const updateCharCount = (editor) => {
    let editorText = editor.getData(); // Get editor content as plain text
    let plaintext = removeHtmlTags(editorText); // Remove HTML tags
    let charCount = plaintext.length; // Count characters
    $('#char-count').text('(Antal tegn: ' + charCount + ')');
    
    return charCount;
}

const removeHtmlTags = (htmlString) => {
    return htmlString.replace(/<[^>]*>|(\r\n|\n|\r)/gm, '').replace(/&nbsp;/g, ' ');
}

// hyperlink edit i ckeditor virker ikke i bootstrap modal, og skal derfor have sat fokus
export const enableFocusForCkEditorInModal = (document) => {
    $.fn.modal.Constructor.prototype.enforceFocus = function() {
        $( document )
            .off( 'focusin.bs.modal' ) // gardering mod uendligt focus loop
            .on( 'focusin.bs.modal', $.proxy( function( e ) {
                if (
                    this.$element[ 0 ] !== e.target && !this.$element.has( e.target ).length
                    && !$( e.target ).closest( '.cke_dialog, .cke' ).length
                ) {
                    this.$element.trigger( 'focus' );
                }
            }, this ) );
    };
}

// Datatables kan ikke håndtere at submitte de pagede sider, da de ikke findes i DOM'en. Dette håndterer checkboxes ved at injecte en hidden
export const dataTableSubmitHandlerCheckbox = (formSelector, tableSelector) => {
    const $form = $(formSelector);
    const table = $(tableSelector).dataTable().api();

    table.$('input[type="checkbox"]').each(function()  {

        // If checkbox doesn't exist in DOM
        if ($.contains(document, this)) {
            return;
        }

        if ($(this).prop("checked") === false) {
            return;
        }

        $form.append($("<input>").attr("type", "hidden").attr("name", this.name).val(this.value));
    });
};


export const clearSelectedStudents = () => {
    sessionStorage.removeItem("ValgteElever");
};

export const initSaveStudents = () => {
    
    setElevValgtListener(getSavedStudents());

    $("#elevDataTable").on("draw.dt", function () {
        setElevValgtListener(getSavedStudents());
    });
};

export const getSavedStudents = () => {
    return JSON.parse(sessionStorage.getItem("ValgteElever"));
};

export const loadSavedStudents = () => {

    const valgteElever = getSavedStudents();
    if (valgteElever) {
        valgteElever.map((element) => {
            $(`#elevDataTable .ElevValgt[value=${element}]`).prop("checked", true);
        });
    }
};

const setElevValgtListener = (valgteElever) => {
    $("#elevDataTable .ElevValgt").off("change").on("change", function () {
        const elevForloebId = $(this).val();

        if (!valgteElever) {
            valgteElever = [];
        }

        if ($(this).prop("checked")) {
            if ($.inArray(elevForloebId, valgteElever) < 0) {
                valgteElever.push(elevForloebId);
            }
        } else {

            const indexOfElement = $.inArray(elevForloebId, valgteElever);

            if (indexOfElement >= 0) {

                valgteElever.splice(indexOfElement, 1);
            }
        }

        sessionStorage.setItem("ValgteElever", JSON.stringify(valgteElever));
    });
};