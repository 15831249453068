import "ditmer-embla";
import "datatables.net-bs";
import "ditmer-embla";
// @ts-ignore

/////////////////////////////

let allowedFileTypes = [""];
const allowedFileSize = 10 * 1024 * 1024;
const allowedFileNameLength = 100;

const fileTypeErrorMessage = "Filtypen er ikke tilladt";
const fileSizeErrorMessage = "Du kan ikke uploade filer som er større end 5MB";
const fileNameLengthErrorMessage = "Navnet på den valgte fil er for langt";

/////////////////////////////

export const InitFileValidation = (elementToValidate, allowedTypes, onErrorMethod, onSuccessMethod) => {
    $(elementToValidate).off().on("change", function(e){
        
        const handleErrorMethod = onErrorMethod !== undefined ? onErrorMethod : handleError;
        hideErrors();

        allowedFileTypes = allowedTypes;

        let file = $(this).prop("files")[0];
        
        if (file !== undefined){
            let [isFileTypeValid, isFileSizeValid, isFileNameLengthValid] = validateFile(file);
            
            if (!isFileTypeValid)
                handleErrorMethod(fileTypeErrorMessage, e, this);

            else if (!isFileSizeValid)
                handleErrorMethod(fileSizeErrorMessage, e, this);
            
            else if (!isFileNameLengthValid)
                handleErrorMethod(fileNameLengthErrorMessage, e, this)
            
            else {
                if (onSuccessMethod !== undefined)
                    onSuccessMethod();
                $(this).siblings("label").children("div .text").text(file.name);
            }
        }
    })
}


/////////////////////////////////


export const validateFile = (file, allowedtypes = allowedFileTypes) => {
    allowedFileTypes = allowedtypes;
    return [validateFileType(file, allowedFileTypes), validateFileSize(file, allowedFileSize), validateFileNameLength(file, allowedFileNameLength)]
}

export const validateFileType = (file, allowedTypes) => {
    const fileNameAsFormattedString = file.name.toLowerCase().replace(/\s/g, '');
    return allowedTypes.some(type => fileNameAsFormattedString.endsWith(type));
};

export const validateFileNameLength = (file, allowedSize) => {
    return file.name.length > 0 && file.name.length <= allowedSize;
};

export const validateFileSize = (file, allowedSize) => {
    return file.size <= allowedSize;
};

export const validateFileContentType = (file, allowedContentHeaderTypes) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = function() {
            const arr = (new Uint8Array(reader.result)).subarray(0, 4);
            let header = "";
            for (let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
            }

            allowedContentHeaderTypes.includes(header)? resolve(true) : reject("Ugyldig filtype. Vælg venligst en ny fil");
        };

        reader.onerror = function() {reject("Der skete en fejl under læsning af filen");};

        reader.readAsArrayBuffer(file);
    });
}


////////////////////////////


const handleError = (errorMessage, e, validationElement) => {
    e.preventDefault();
    $(validationElement).siblings("label").children("div .text").text("Vælg fil");
    displayErrors([errorMessage])
    return;
}

const hideErrors = () => {
    let errorField = $("#error-field");
    errorField.hide();
}

const displayErrors = (errors) => {
    let errorField = $("#error-field");
    errorField.show();
    let ulMarker = errorField.children("ul");

    ulMarker.empty();
    $(errors).each((i,e) => {
        ulMarker.append("<li>" + e + "</li>");
    })
}

